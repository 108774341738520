<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.sms_profile_name }}</div>
      <div
        v-for="(parameter, key) in contentForm.json_payload"
        :key="'json_payload_parameter_' + key"
      >
        <div class="titles">{{ key }}</div>
        <div class="details">
          {{ key.toLowerCase() == "password" ? "**********" : parameter }}
        </div>
      </div>
      <!--      <div class="titles">JSON</div>-->
      <!--      <div class="details">{{ contentForm.json_payload }}</div>-->

      <el-button
        @click="openModal = true"
        class="editBtn"
        :disabled="!can('manage.sms-profiles.write')"
        >{{ __("Edit") }}</el-button
      >
      <el-button
        @click="
          handleDelete(contentForm, 'SmsProfile', contentForm.sms_profile_id)
        "
        class="deleteBtn"
        :disabled="!can('manage.sms-profiles.write')"
        >{{ __("Delete") }}</el-button
      >

      <delete-item></delete-item>

      <!--      <div style="margin-top: 20px;">-->
      <!--        <el-button-->
      <!--          plain-->
      <!--          @click="handleSendTestSms(contentForm.sms_profile_id)"-->
      <!--          class="deleteBtn"-->
      <!--          >Test</el-button-->
      <!--        >-->
      <!--      </div>-->

      <!--      <div style="margin-top: 20px;">-->
      <!--        <el-button-->
      <!--          plain-->
      <!--          @click.prevent="handleSendTestSms(contentForm.sms_profile_id)"-->
      <!--        >-->
      <!--          Send test sms-->
      <!--        </el-button>-->
      <!--      </div>-->
    </div>

    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div class="sms-profile-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('SMS Profile')" :contentId="id" />
          </el-col>
        </el-row>

        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="sms_profile_name" :label="__('Name')">
                  <el-input v-model="contentForm.sms_profile_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="sms_provider_id"
                  :label="__('SMS provider')"
                >
                  <el-select
                    filterable
                    v-model="contentForm.sms_provider_id"
                    style="width: 100%;"
                    default-first-option
                    @change="selectSmsProvider"
                  >
                    <el-option
                      v-for="smsProvider in smsProviders"
                      :key="'_sms_provider_' + smsProvider.sms_provider_id"
                      :value="smsProvider.sms_provider_id"
                      :label="smsProvider.sms_provider_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              v-for="(param, key) in smsProfileParams"
              :key="'_sms_profile_parameter_' + key"
            >
              <el-col :span="12" :offset="6">
                <el-form-item
                  :prop="'json_payload.' + param.key"
                  :key="param.key"
                  :label="__(param.label)"
                >
                  <el-input
                    v-if="param.key.toLowerCase() == 'password'"
                    v-model="contentForm.json_payload[param.key]"
                    show-password
                  ></el-input>
                  <el-input
                    v-else
                    v-model="contentForm.json_payload[param.key]"
                  ></el-input>
                  <!--                  <el-input v-model="payload[param.key]"></el-input>-->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!can('manage.sms-profiles.write')"
                    :primaryKey="id"
                  />
                  <el-button @click="handleCancel" class="cancelBtn">{{
                    __("Cancel")
                  }}</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import DeleteItem from "@/components/DeleteItem";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    DeleteItem,
    SaveButton
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("smsproviders", {
      smsProviders: state => state.smsProviders
    }),

    ...mapGetters("smsproviders", {
      getSmsProvider: "getSmsProvider"
    })
  },

  data() {
    return {
      isSubmitting: false,
      rules: {
        sms_profile_name: [
          {
            required: true,
            message: __("SMS profile name cannot be empty"),
            trigger: "blur"
          }
        ],
        sms_provider_id: [
          {
            required: true,
            message: __("Please select a sms provider"),
            trigger: "change"
          }
        ]
      },

      smsProviderParams: {},
      payload: {},

      // new
      selectedSmsProvider: {},
      smsProfileParams: [],

      forceDeleteEventName: "initiate-force-delete-SmsProfile"
    };
  },

  methods: {
    ...mapActions("smsprofiles", {
      createSmsProfile: "createSmsProfile",
      updateSmsProfile: "updateSmsProfile",
      deleteContentMethod: "deleteSmsProfile",
      undoDeleteContent: "undoDeleteSmsProfile",
      sendTestSms: "sendTestSms"
    }),

    handleSendTestSms(sms_profile_id) {
      this.$prompt(__("Recipient"), __("Sms"), {
        confirmButtonText: __("Send"),
        cancelButtonText: __("Cancel"),
        inputErrorMessage: __("Invalid Sms"),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = __("Loading...");
            this.sendTestSms({
              sms_profile_id,
              recipient: instance.inputValue,
              smsContent: __("Hello from Studio 7")
            }).then(() => {
              instance.confirmButtonLoading = false;
              done();
            });
          } else {
            done();
          }
        }
      })
        .then(() => {
          this.$message({
            type: "success",
            message: __("Sms sent")
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: __("Test send sms cancelled")
          });
        });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createSmsProfile : this.updateSmsProfile;

          // this.contentForm.json_payload = { ...this.payload };
          this.contentForm.is_enabled = 1;
          this.contentForm.ac_id = this.selectedAccountId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Sms Profile added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Sms Profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    //  new
    selectSmsProvider(smsProviderId) {
      if (smsProviderId) {
        this.selectedSmsProvider = _.cloneDeep(
          this.getSmsProvider(smsProviderId)
        );
        this.loadParameters();
      }
    },

    loadParameters() {
      // Reset params before loading
      this.smsProfileParams = [];

      if (this.selectedSmsProvider.json_data.request) {
        for (let i in this.selectedSmsProvider.json_data.request) {
          const param = { ...this.selectedSmsProvider.json_data.request[i] };
          if (param.type === "sms_profile") {
            this.smsProfileParams.push(param);

            if (param.required == true) {
              this.$set(this.rules, "json_payload." + param.key, {
                required: true,
                message: __(param.label) + __(" cannot be empty"),
                trigger: "blur"
              });
            }
          }
        }
      }
    },

    initializeSmsProfile() {
      this.isSubmitting = true;
      if (this.id !== -1) {
        if (this.contentForm.sms_provider_id) {
          this.selectSmsProvider(this.contentForm.sms_provider_id);
        }
      } else {
        this.$set(this.contentForm, "sms_provider_id", null);
        this.$set(this.contentForm, "sms_profile_name", "");
        this.$set(this.contentForm, "json_payload", {});
      }
      this.isSubmitting = false;
    }
  },

  watch: {
    openModal(val) {
      if (val === true) {
        this.smsProfileParams.splice(0, this.smsProfileParams.length);
        this.initializeSmsProfile();
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";
@import "~@/styles/content-edit-info.scss";
.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;
  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
